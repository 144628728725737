var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { checkDevice } from "@pernod-ricard-global-cms/jsutils";
import lazyloader from "@pernod-ricard-global-cms/cbllazyloader";
import jsAssets from "Assets/js/modules/jsAssets";
import checkBrowserInfo from "Assets/js/modules/check-browser-info";
import colourPalettes from "@pernod-ricard-global-cms/cblcolourpalettes";
import siteHeader from "Assets/js/components/site-header";
import { ttfb } from "Assets/js/modules/perf-tests";
lazyloader.options.filePath = "js/blocks/";
lazyloader.options.assetArray = jsAssets.dynamicAssets;
let runLazyLoad = () => {
    lazyloader.lazyloaderInit();
};
function setupColours() {
    globalThis.requestAnimationFrame(() => {
        const colourData = globalThis.colourconfig;
        colourPalettes(colourData);
    });
}
function headerJs() {
    const header = document.querySelector(".site-header");
    siteHeader({ block: header });
}
function yieldToMain() {
    return new Promise((resolve) => {
        setTimeout(resolve, 0);
    });
}
window.addEventListener("DOMContentLoaded", () => {
    function runSiteJs() {
        return __awaiter(this, void 0, void 0, function* () {
            // Create an array of functions to run:
            const tasks = [
                ttfb,
                checkDevice,
                checkBrowserInfo,
                setupColours,
                runLazyLoad,
                headerJs,
            ];
            // Loop over the tasks:
            while (tasks.length > 0) {
                // Shift the first task off the tasks array:
                const task = tasks.shift();
                // Run the task:
                task();
                // Yield to the main thread:
                yield yieldToMain();
            }
        });
    }
    runSiteJs();
    // homepage, word animation, should hav animate-word-websites class
    const rotateWords = (element, wordToReplace, arrayOfWords) => {
        if (element) {
            const span = document.createElement('span');
            span.textContent = element.textContent;
            element.textContent = '';
            element.appendChild(span);
            let current = 0;
            span.style.width = span.offsetWidth + 'px';
            span.style.overflow = 'visible';
            span.style.whiteSpace = 'nowrap';
            span.style.display = 'inline-block';
            span.textContent = span.textContent.replace(wordToReplace, arrayOfWords[current]);
            arrayOfWords.push(wordToReplace);
            const regex = new RegExp(arrayOfWords.join('|'), 'g');
            const replaceText = () => {
                // Replace 'websites' with the next word in the array
                span.textContent = span.textContent.replace(regex, arrayOfWords[current]);
                // Update the current index to cycle through words
                current = (current + 1) % arrayOfWords.length;
                // Set the timeout for the next replacement
                setTimeout(replaceText, 4000); // Adjust time as needed
            };
            // Start the loop
            replaceText();
        }
    };
    if (document.querySelector('.animate-word-websites h1'))
        rotateWords(document.querySelector('.animate-word-websites h1'), 'websites', ['solutions', 'careers']);
});
